exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-our-practice-tsx": () => import("./../../../src/pages/about-us/our-practice.tsx" /* webpackChunkName: "component---src-pages-about-us-our-practice-tsx" */),
  "component---src-pages-about-us-our-team-tsx": () => import("./../../../src/pages/about-us/our-team.tsx" /* webpackChunkName: "component---src-pages-about-us-our-team-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-patient-resources-accessibility-tsx": () => import("./../../../src/pages/patient-resources/accessibility.tsx" /* webpackChunkName: "component---src-pages-patient-resources-accessibility-tsx" */),
  "component---src-pages-patient-resources-blog-tsx": () => import("./../../../src/pages/patient-resources/blog.tsx" /* webpackChunkName: "component---src-pages-patient-resources-blog-tsx" */),
  "component---src-pages-patient-resources-faq-tsx": () => import("./../../../src/pages/patient-resources/faq.tsx" /* webpackChunkName: "component---src-pages-patient-resources-faq-tsx" */),
  "component---src-pages-patient-resources-financing-options-tsx": () => import("./../../../src/pages/patient-resources/financing-options.tsx" /* webpackChunkName: "component---src-pages-patient-resources-financing-options-tsx" */),
  "component---src-pages-patient-resources-new-patients-tsx": () => import("./../../../src/pages/patient-resources/new-patients.tsx" /* webpackChunkName: "component---src-pages-patient-resources-new-patients-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-test-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/blog-posts/test.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-posts-test-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-dental-bridges-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/services/dental-bridges.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-dental-bridges-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-dental-cleanings-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/services/dental-cleanings.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-dental-cleanings-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-dental-crowns-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/services/dental-crowns.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-dental-crowns-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-fillings-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/services/Fillings.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-fillings-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-flexible-based-dentures-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/services/flexible-based-dentures.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-flexible-based-dentures-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-general-tooth-extration-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/services/general-tooth-extration.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-general-tooth-extration-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-partial-dentures-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/services/partial-dentures.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-partial-dentures-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-periodontal-care-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/services/periodontal-care.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-periodontal-care-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-porcelain-crowns-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/services/porcelain-crowns.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-porcelain-crowns-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-privacy-policy-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/services/privacy-policy.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-privacy-policy-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-root-canal-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/services/root-canal.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-root-canal-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-teeth-whitening-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/services/teeth-whitening.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-teeth-whitening-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-veneers-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/services/veneers.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-veneers-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-wisdom-tooth-extraction-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/claremont-nc-dentist/claremont-nc-dentist/src/content/services/wisdom-tooth-extraction.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-wisdom-tooth-extraction-mdx" */)
}

