import styled from '@emotion/styled';
import { theme } from './GlobalStyles';
import { Link } from 'gatsby';
import { Icon } from '@/Icon';

interface PrimaryProps {
    fill?: boolean;
}

export const ButtonPrimary = styled.button<PrimaryProps>`
    background: ${({ fill }) => (fill ? `${theme.colors.red2}` : `none`)};
    color: ${({ fill }) => (fill ? `#ffffff` : `${theme.colors.red2}`)};
    border: ${({ fill }) =>
        fill ? `2px solid ${theme.colors.red2}` : `2px solid ${theme.colors.red2}`};
    font-size: 14px;
    border-radius: 100px;
    font-weight: 500;
    line-height: 162%;
    letter-spacing: 1.92px;
    padding: 14px 24px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in;
    cursor: pointer;
    text-transform: uppercase;
    a {
        color: ${({ fill }) => (fill ? `${theme.colors.white}` : `${theme.colors.red2}`)};
    }
    svg {
        width: 18px;
        transition: 0.2s ease-in;
        margin-right: 12px;
        fill: ${({ fill }) => (fill ? `${theme.colors.gray1}` : `${theme.colors.red2}`)};
    }
    @media ${theme.device.tablet} {
        &:hover {
            transform: translateY(-2px);
            scale: 1.12rem;
            background: ${({ fill }) => (fill ? `none` : `${theme.colors.red2}`)};
            color: ${({ fill }) => (fill ? `${theme.colors.white}` : `${theme.colors.gray1}`)};
            svg {
                fill: ${({ fill }) => (fill ? `${theme.colors.red2}` : `${theme.colors.gray1}`)};
            }
        }
    }
    @media ${theme.device.laptopL} {
        font-size: 20px;
        height: 64px;
        padding: 14px 32px;
    }
`;

interface Color {
    color: 'red' | 'white';
}

const StyledSecondaryButton = styled.button<Color>`
    border: none;
    cursor: pointer;
    text-transform: capitalize;
    background: none;
    line-height: 24px;
    padding-left: 0px;
    a {
        text-decoration: none;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        color: ${({ color }) => color === 'red' && `${theme.colors.red2}`};
        color: ${({ color }) => color === 'white' && `${theme.colors.gray1}`};
    }
    svg {
        fill: ${({ color }) => color === 'red' && `${theme.colors.red2}`};
        fill: ${({ color }) => color === 'white' && `${theme.colors.gray1}`};
        width: 16px;
    }
    div {
        margin-left: 16px;
        padding: 10px;
        display: flex;
        transition: 0.2s ease-in;
        align-items: center;
        justify-content: center;
    }
    &:hover {
        div {
            transform: rotate(0deg);
            transform: translateX(2px);
        }
    }
`;

interface Props {
    children: string | React.ReactNode;
    color: 'red' | 'white';
    className?: string;
    link: string;
}
export const SecondaryButton = ({ children, color, link }: Props) => {
    return (
        <StyledSecondaryButton color={color}>
            <Link to={link}>
                {children}
                <div>
                    <Icon name="arrow-short" />
                </div>
            </Link>
        </StyledSecondaryButton>
    );
};

interface Color {
    color: 'red' | 'white';
}

const StyledButtonLink = styled.button<Color>`
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 162%; /* 22.68px */
    letter-spacing: 1.4px;
    padding: 0;
    a {
        text-decoration: underline;
        display: flex;
        align-items: center;

        color: ${({ color }) => color === 'red' && `${theme.colors.red2}`};
        color: ${({ color }) => color === 'white' && `${theme.colors.gray1}`};
    }
    svg {
        width: 17px;
        margin-left: 10px;
        fill: ${({ color }) => color === 'red' && `${theme.colors.red2}`};
        fill: ${({ color }) => color === 'white' && `${theme.colors.gray1}`};
    }
    div {
        display: flex;
        transition: 0.2s ease-in;
        align-items: center;
        justify-content: center;
    }
    &:hover {
        div {
            transform: rotate(0deg);
            transform: translateX(2px);
        }
    }
    @media ${theme.device.desktop} {
        font-size: 18px;
    }
`;

interface Props {
    children: string | React.ReactNode;
    color: 'red' | 'white';
    className?: string;
    link: string;
}
export const ButtonLink = ({ children, color, link }: Props) => {
    return (
        <StyledButtonLink color={color}>
            <Link to={link}>
                {children}
                <div>
                    <Icon name="arrow-short" />
                </div>
            </Link>
        </StyledButtonLink>
    );
};
