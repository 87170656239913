import React from 'react';
import styled from '@emotion/styled';
import { theme } from './layout/GlobalStyles';

const StyledMap = styled.div`
    width: 100%;
    iframe {
        margin-top: 32px;
        width: 100%;
        border-radius: 16px;
        height: 340px;
    }

    @media ${theme.device.laptop} {
        iframe {
            margin-top: 0;
            height: 100vh;
            max-height: 640px;
        }
    }
`;

interface ComponentProps {
    children: React.ReactNode;
}

export const Map: React.FunctionComponent<ComponentProps> = ({ children }) => {
    return <StyledMap>{children}</StyledMap>;
};
