import React from 'react';
import styled from '@emotion/styled';
import { theme } from './layout/GlobalStyles';

interface StyledProps {
    background: 'gray' | 'red' | 'dark' | 'white' | 'light_gray' | 'black';
    narrow?: boolean;
    border?: boolean;
}

const StyledSection = styled.section<StyledProps>`
    padding: 64px 16px;
    background: ${({ background }) => background === 'dark' && `#191B18`};
    background: ${({ background }) => background === 'black' && `#171304`};
    background: ${({ background }) => background === 'light_gray' && `#FAFAFA`};
    background: ${({ background }) => background === 'white' && `#f7f5ee`};
    background: ${({ background }) => background === 'red' && `#24180F`};
    background: ${({ background }) => background === 'gray' && `${theme.colors.gray2}`};
    border-bottom: ${({ border }) => border && `2px solid #d1dadc`};
    @media ${theme.device.laptop} {
        padding: ${({ narrow }) => (narrow ? `64px 190px 64px 190px` : `64px`)};
    }
    @media ${theme.device.desktop} {
    }
`;

interface ComponentProps extends StyledProps {
    children: React.ReactNode;
}

export const Section: React.FunctionComponent<ComponentProps> = ({
    background,
    children,
    narrow,
}) => {
    return (
        <StyledSection narrow={narrow} background={background}>
            {children}
        </StyledSection>
    );
};
