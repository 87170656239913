import { Icon } from '@/Icon';
import { theme } from './GlobalStyles';

import styled from '@emotion/styled';
import { information } from 'information';

interface Props {
    color: 'white' | 'red' | 'black';
    className?: string;
}

const LocationLinkStyled = styled.div<Props>`
    min-width: 32px;
    height: 32px;
    border-radius: 5px;
    margin-bottom: 1em;
    transition: all 0.2s ease-in-out;
    :hover {
        a {
            color: ${theme.colors.red2};
        }
    }

    svg {
        fill: ${({ color }) =>
            (color === 'red' && theme.colors.red2) || (color === 'white' && theme.colors.gray1)};
    }
    a {
        line-height: 152.5%;
        transition: all 0.2s ease-in-out;
        text-decoration: underline;
        display: flex;
        font-size: 16px;
        color: ${({ color }) =>
            (color === 'red' && theme.colors.red2) ||
            (color === 'white' && theme.colors.white) ||
            (color === 'black' && theme.colors.gray5)};
        align-items: center;
        .marker {
            width: 18px;
        }
        .email {
            width: 24px;
        }
        .mobile {
            width: 25px;
        }
        svg {
            transition: all 0.2s ease-in-out;
            transform: scale(1);
        }
        span {
            margin-left: 17px;
        }
        &:hover {
            svg {
                transform: scale(1.1);
            }
        }
    }
`;

interface ComponentProps extends Props {
    type: 'email' | 'phone' | 'map';
    className?: string;
}

export const LocationLink: React.FC<ComponentProps> = ({ type, className, color }) => {
    return (
        <LocationLinkStyled color={color} className={className}>
            {type === 'phone' && (
                <a href={`tel:${information.phone}`} target="_blank" rel="noreferrer">
                    <Icon name="phone-white" className="mobile" />

                    <span>{information.phone}</span>
                </a>
            )}
            {type === 'email' && (
                <a href={`mailto:${information.email}`} target="_blank" rel="noreferrer">
                    <Icon name="mail-white" className="email" />
                    <span>{information.email}</span>
                </a>
            )}
            {type === 'map' && (
                <a
                    style={{ position: 'relative', left: '3px' }}
                    href={`${information.locationUrl}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Icon name="marker" className="marker" />
                    <span>{information.location}</span>
                </a>
            )}
        </LocationLinkStyled>
    );
};
